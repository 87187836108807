<template>
  <!-- ======= About Section ======= -->
  <section id="about" class="about">
    <!-- ======= About Me ======= -->
    <div class="about-me container">
      <div class="section-title">
        <h2>HAKKIMIZDA</h2>
        <p>MİSYONUMUZ / VİZYONUMUZ</p>
      </div>

      <div class="row">
        <div class="col-lg-4" data-aos="fade-right">
          <img src="~@/assets/ystf-logo.png" class="img-fluid" alt="" width="600" height="600" />
        </div>
        <div class="col-lg-8 pt-4 pt-lg-0 content" data-aos="fade-left">
          <h3>Biz Kimiz?</h3>
          <p class="fst-italic">
            Kendi Ekmeğini Kendi Yapmak İsteyenler! Samsun’da imal edilen Yörük Seyyar Taş
            Fırınları’nı inceleyip istediğiniz ölçüde bir tane sipariş edebilirsiniz… Büyük
            fırınlarda bulunan bütün özellikleri bünyesinde barındıran,özel taban tuğlasından, baca
            sitemine, çatısından malzemelerinizi saklayabileceğiniz özel bölmesine kadar her şey
            profesyonelce düşünülmüş. Tekerlekli ve 5-15 köy ekmeği kapasiteli köy ekmeğinin yanı
            sıra, kendi pide ve lahmacununuzu yapabilirsiniz.
          </p>
          <div class="row">
            <div class="col-lg-6">
              <ul>
                <li>
                  <i class="bi bi-check2-circle"></i>
                  <strong>30 Yıllık İş Tecrübesi</strong>
                </li>
                <li>
                  <i class="bi bi-check2-circle"></i>
                  <strong>A Kalite Malzeme</strong>
                </li>
              </ul>
            </div>
            <div class="col-lg-6">
              <ul>
                <li>
                  <i class="bi bi-check2-circle"></i>
                  <strong>Ergonomik Tasarım</strong>
                </li>
                <li>
                  <i class="bi bi-check2-circle"></i>
                  <strong>Müşteri Memnuniyeti</strong>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End About Me -->
  </section>
  <!-- End About Section -->
</template>

<script>
export default {};
</script>

<style>
/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about-me .content h3 {
  font-weight: 700;
  font-size: 26px;
  color: #ffc107;
}
.about-me .content ul {
  list-style: none;
  padding: 0;
}
.about-me .content ul li {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}
.about-me .content ul strong {
  margin-right: 10px;
}
.about-me .content ul i {
  font-size: 16px;
  margin-right: 5px;
  color: #ffc107;
  line-height: 0;
}
.about-me .content p:last-child {
  margin-bottom: 0;
}
</style>
