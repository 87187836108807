<template>
  <div>
    <!-- ======= Products Section ======= -->
    <section id="products" class="products" v-if="showProducts">
      <div class="container">
        <div class="section-title">
          <h2>ÜRÜNLERİMİZ</h2>
          <p>SEYYAR TAŞ FIRINLAR / BARBEKÜLER</p>
        </div>

        <div class="row">
          <div class="col-lg-12 d-flex justify-content-center">
            <ul id="products-filters">
              <li
                v-for="(value, index) in buttonTags"
                :key="index"
                :class="{ 'filter-active': filteredValue == filterValues[index] }"
                @click="filter(filterValues[index])">
                {{ value }}
              </li>
            </ul>
          </div>
        </div>

        <div class="row grid">
          <div
            v-for="(product, index) in products"
            :key="index"
            :class="'col-lg-4 col-md-6 grid-item ' + product.type">
            <div class="products-wrap" @click="productToggle(product.code)">
              <img v-bind:src="product.image" class="img-fluid" @load="onImageLoad" />
              <div class="products-info">
                <h4>{{ product.name }}</h4>
                <p>{{ product.code }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End products Section -->

    <product-details :product="product" v-if="showProductDetails"> </product-details>
  </div>
</template>

<script>
import axios from 'axios';
import Isotope from 'isotope-layout';

import ProductDetails from '../components/ProductDetails.vue';

export default {
  data() {
    return {
      showProducts: true,
      showProductDetails: false,
      product: null,
      products: [],
      buttonTags: ['HEPSİ', 'Seyyar Fırınlar', 'Barbeküler', 'SABİT FIRINLAR'],
      filterValues: ['all', 'seyyar_firinlar', 'barbekuler', 'sabit_firinlar'],
      filteredValue: 'all',
      isotope: null,
    };
  },
  components: {
    productDetails: ProductDetails,
  },
  methods: {
    fetchData() {
      axios
        .get(`${process.env.VUE_APP_ROOT_URL}/products.json`)
        .then((res) => {
          this.products = res.data;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
    },
    // Product Details
    productToggle(code) {
      this.showProducts = false;
      this.showProductDetails = true;
      this.products.forEach((product) => {
        if (product.code === code) {
          this.product = product;
        }
      });
    },
    // Masonry Layout¬
    onImageLoad() {
      this.isotope = new Isotope('.grid', {
        itemSelector: '.grid-item',
        layoutMode: 'masonry',
      });
    },
    filter(value) {
      this.filteredValue = value;
      if (value === 'all') {
        this.isotope.arrange({
          filter: false,
        });
      } else {
        this.isotope.arrange({
          filter: `.${value}`,
        });
      }
    },
  },
  created() {
    this.fetchData();
  },
};
</script>

<style>
/*--------------------------------------------------------------
# Products
--------------------------------------------------------------*/
.products .grid-item {
  margin-bottom: 30px;
}
.products #products-filters {
  padding: 0;
  margin: 0 auto 15px auto;
  list-style: none;
  text-align: center;
  border-radius: 50px;
  padding: 2px 15px;
}
.products #products-filters li {
  cursor: pointer;
  display: inline-block;
  padding: 8px 16px 10px 16px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  color: #fff;
  background: rgba(255, 255, 255, 0.1);
  margin: 0 3px 10px 3px;
  transition: all 0.3s ease-in-out;
  border-radius: 4px;
}
.products #products-filters li:hover,
.products #products-filters li.filter-active {
  background: #ffc107;
}
.products #products-filters li:last-child {
  margin-right: 0;
}
.products .products-wrap {
  transition: 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  background: rgba(255, 255, 255, 0.1);
  cursor: pointer;
}
.products .products-wrap::before {
  content: "";
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 30px;
  right: 30px;
  top: 30px;
  bottom: 30px;
  transition: all ease-in-out 0.3s;
  z-index: 2;
  opacity: 0;
}
.products .products-wrap .products-info {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  z-index: 3;
  transition: all ease-in-out 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
.products .products-wrap .products-info::before {
  display: block;
  content: "";
  width: 48px;
  height: 48px;
  position: absolute;
  top: 35px;
  left: 35px;
  border-top: 3px solid #fff;
  border-left: 3px solid #fff;
  transition: all 0.5s ease 0s;
  z-index: 9994;
}
.products .products-wrap .products-info::after {
  display: block;
  content: "";
  width: 48px;
  height: 48px;
  position: absolute;
  bottom: 35px;
  right: 35px;
  border-bottom: 3px solid #fff;
  border-right: 3px solid #fff;
  transition: all 0.5s ease 0s;
  z-index: 9994;
}
.products .products-wrap .products-info h4 {
  font-size: 20px;
  color: #ffc107;
  font-weight: 600;
}
.products .products-wrap .products-info p {
  color: #ffc107;
  font-size: 14px;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
}
.products .products-wrap .products-links {
  text-align: center;
  z-index: 4;
}
.products .products-wrap .products-links a {
  color: #fff;
  margin: 0 2px;
  font-size: 28px;
  display: inline-block;
  transition: 0.3s;
}
.products .products-wrap .products-links a:hover {
  color: #ffc107;
}
.products .products-wrap:hover::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
}
.products .products-wrap:hover .products-info {
  opacity: 1;
}
.products .products-wrap:hover .products-info::before {
  top: 15px;
  left: 15px;
}
.products .products-wrap:hover .products-info::after {
  bottom: 15px;
  right: 15px;
}
</style>
