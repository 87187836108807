<template>
  <div id="app">
    <!-- TODO
      - arkaplan gemometrik sekiller eklencek => TAMAMLANDI
      - urunler kismi tasarlancak urun detay sayfasi eklencek => TAMAMLANDI
      - foto galeri sayfasi eklencek  => TAMAMLANDI
      - Urunlerim detay sayfasi yapilcaka =>
      - Galeri kismina facebookdan fotograflar eklencek =>
      - Anasayfaya foto banner slayt =>
      - Navbar logo ekle =>
      - Urunler icin model olustur, bilgileri oilustur => FURKANLA
      - Websitesi fiyat analizi yap =>
    -->
    <particles
      id="tsparticles"
      url="/particles.json"
      style="background-color: #0c0c0c;"/>

    <navbar></navbar>

    <router-view></router-view>

    <credits></credits>
  </div>
</template>

<script>
import Navbar from './components/Navbar.vue';
import Credits from './components/Credits.vue';

export default {
  data() {
    return {};
  },
  components: {
    navbar: Navbar,
    credits: Credits,
  },
};
</script>

<style>
.fade-enter-active, .fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

body {
  font-family: "Open Sans", sans-serif;
  background-color: #040404;
  color: #fff;
  position: relative;
  background: transparent;
}
body::before {
  content: "";
  position: fixed;
  /* background: #fff; */
  background: #040404 url('~@/assets/tugla-deseni.png') top right repeat;
  background-size: cover;
  left: 0;
  right: 0;
  top: 0;
  height: 100vh;
  z-index: -1;
}
@media (min-width: 1024px) {
  body::before {
    background-attachment: fixed;
  }
}

a {
  color: #ffc107;
  text-decoration: none;
}

a:hover {
  color: #ffc107;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Raleway", sans-serif;
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  overflow: hidden;
  position: absolute;
  width: 100%;
  top: 100px;
  bottom: auto;
  padding-bottom: 45px;
  opacity: 1;
  transition: ease-in-out 0.4s;
  z-index: 2;
}
section .container {
  background: rgba(0, 0, 0, 0.9);
  padding: 30px;
  border-radius: 20px;
}
@media (max-width: 768px) {
  section {
    top: 80px;
  }
}
.section-title h2 {
  font-size: 14px;
  font-weight: 500;
  padding: 0;
  line-height: 1px;
  margin: 0 0 20px 0;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #aaaaaa;
  font-family: "Poppins", sans-serif;
}
.section-title h2::after {
  content: "";
  width: 120px;
  height: 1px;
  display: inline-block;
  background: #ffc107;
  margin: 4px 10px;
}
.section-title p {
  margin: 0;
  margin: -15px 0 15px 0;
  font-size: 36px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  color: #fff;
}
</style>
