<template>
  <!-- ======= product Details ======= -->
  <section id="product-details" class="product-details">
    <div class="container">
      <div class="section-title">
        <div class="back" @click="productToggle()">
          <i class="bi bi-arrow-left-circle"></i>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-8">
          <h2 class="product-title">{{ product.name.toUpperCase() }}</h2>

          <div class="product-details-slider swiper-container">
            <div class="swiper-wrapper align-items-center">
              <div class="swiper-slide"
                   :key="index"
                   v-for="(image, index) in product.images">
                <img :src="image">
              </div>
            </div>
            <div class="swiper-pagination"></div>
          </div>

        </div>
        <div class="col-lg-4 product-info">
          <h3>Özellikler</h3>
          <ul>
            <li><strong>Ürün Kodu:</strong> {{ product.code }}</li>
            <li><strong>Kapasite:</strong> {{ product.capacity }}</li>
            <li><strong>Yalıtım Durumu:</strong>
               {{ product.insulation ?  'Mevcut' : 'Mevcut değil' }}
            </li>
            <li><strong>Tekerlek:</strong>
               {{ product.mobility ?  'Mevcut': 'Mevcut değil' }}
            </li>
            <li><strong>Çatı:</strong>
               {{ product.roof ?  'Mevcut': 'Mevcut değil' }}
            </li>
            <li><strong>Termometre:</strong>
               {{ product.thermometer ?  'Mevcut': 'Mevcut değil' }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  <!-- End product Details -->
</template>

<script>
import Swiper from 'swiper';

import 'swiper/swiper-bundle.min.css';

export default {
  data() {
    return {
      swiper: null,
    };
  },
  props: {
    product: Object,
  },
  methods: {
    productToggle() {
      this.$parent.showProducts = true;
      this.$parent.showProductDetails = false;
    },
  },
  mounted() {
    this.swiper = new Swiper('.product-details-slider', {
      speed: 400,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
    });
  },
};
</script>

<style>
/*--------------------------------------------------------------
# product Details
--------------------------------------------------------------*/
.product-details .swiper-slide {
  display: flex;
  justify-content: center;
}
.product-details .swiper-slide img {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  cursor: grab;
  height: 700px;
}
.product-details .section-title .back {
  font-size: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  line-height: 1;
  margin-right: 8px;
  border-radius: 50%;
  width: 26px;
  height: 26px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: all ease-in-out 0.3s;
}
.product-details .section-title .back:hover {
  color: #ffc107;
}
.product-details .container {
  padding-top: 20px;
  padding-bottom: 40px;
}
.product-details .product-title {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
  color: #ffc107;
}
.product-details .product-info {
  padding-top: 45px;
}
.product-details .product-info h3 {
  font-size: 22px;
  font-weight: 400;
  margin-bottom: 20px;
}
.product-details .product-info ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}
.product-details .product-info ul li + li {
  margin-top: 10px;
}
.product-details .product-info p {
  font-size: 15px;
  padding: 15px 0 0 0;
}
@media (max-width: 992px) {
  .product-details .product-info {
    padding-top: 20px;
  }
}
.product-details .swiper-pagination {
  margin-top: 20px;
  /* position: relative; */
}
.product-details .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.3);
}
.product-details .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #ffc107;
}
</style>
