<template>
  <!-- ======= Services Section ======= -->
  <section id="services" class="services">
    <div class="container">
      <div class="section-title">
        <h2>HİZMETLERİMİZ</h2>
        <!-- <p>My Services</p> -->
      </div>

      <div class="row">
        <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
          <div class="icon-box">
            <div class="icon"><i class="bx bx-baguette"></i></div>
            <h4><a href="">Sabit Taş Fırınları</a></h4>
            <p>
              <i
                >İstenilen ebatta sabit taş fırın siparişlerı alınır. İster ticari kullanım için
                ister eviniz için set üstü ve sabit fırın seçeneklerimiz vardır.</i
              >
            </p>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
          <div class="icon-box">
            <div class="icon"><i class="bx bx-home-heart"></i></div>
            <h4><a href="">Şömine ve Köy Ocağı</a></h4>
            <p>
              <i
                >Evi için nostaljik bir köşe arayanlar... Hem içinizi ısıtacak hem de evinize hava
                katacak, doğal tuğla görünümlü şömine ve köy ocaklarını sizin için uyguluyoruz.</i
              >
            </p>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0">
          <div class="icon-box">
            <div class="icon"><i class="bx bx-purchase-tag-alt"></i></div>
            <h4><a href="">Fırın Malzemeleri</a></h4>
            <p>
              <i
                >Firmamızda, fırınlarımızın yapımında kullandığımız <span>harman tuğla</span>,
                <span>ateş tuğlası</span> çeşitleri (her ebatta), <span>örgü harcı</span>,
                <span>fırın kapağı</span> satışlarımız da yapılmaktadır.</i
              >
            </p>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
          <div class="icon-box">
            <div class="icon"><i class="bx bxs-truck"></i></div>
            <h4><a href="">Nakliye</a></h4>
            <p>
              <i>Seyyar taş fırınlarımızı Samsun içine ücretsiz olarak kapınıza kadar taşıyoruz.</i>
            </p>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
          <div class="icon-box">
            <div class="icon"><i class="bx bx-store"></i></div>
            <h4><a href="">Bayilik</a></h4>
            <p>
              <i>Türkiye'nin her yerine bayilik verilecektir. Bizimle iletişime geçin.</i>
            </p>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
          <div class="icon-box">
            <div class="icon"><i class="bx bx-arch"></i></div>
            <h4><a href="">Canlı Destek</a></h4>
            <p>
              <i
                >İletişim kısmından Whatsapp hattımıza 7/24 ulaşabilirsiniz. Her zaman
                yanınızdayız.</i
              >
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Services Section -->
</template>

<script>
export default {};
</script>

<style>
/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services .icon-box {
  text-align: center;
  background: rgba(204, 204, 204, 0.1);
  padding: 80px 20px;
  transition: all ease-in-out 0.3s;
}
.services .icon-box .icon {
  margin: 0 auto;
  width: 64px;
  height: 64px;
  background: #ffc107;
  border-radius: 5px;
  transition: all 0.3s ease-out 0s;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  transform-style: preserve-3d;
}
.services .icon-box .icon i {
  color: #fff;
  font-size: 28px;
}
.services .icon-box .icon::before {
  position: absolute;
  content: "";
  left: -8px;
  top: -8px;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 5px;
  transition: all 0.3s ease-out 0s;
  transform: translateZ(-1px);
}
.services .icon-box h4 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 24px;
}
.services .icon-box h4 a {
  color: #fff;
}
.services .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}
.services .icon-box:hover {
  background: rgba(255, 193, 7, 0.5);
  border-color: #ffc107;
}
.services .icon-box:hover .icon {
  background: #fff;
}
.services .icon-box:hover .icon i {
  color: #ffc107;
}
.services .icon-box:hover .icon::before {
  background: #ffc107;
}
.services .icon-box:hover h4 a,
.services .icon-box:hover p {
  color: #fff;
}

.services p span {
  color: #fff;
  border-bottom: 2px solid #ffc107;
  padding-bottom: 2px;
}
</style>
