<template>
  <div id="gallery">
    <!-- ======= Gallery Section ======= -->
    <section id="gallery" class="gallery">
      <div class="container">
        <div class="section-title mb-4">
          <h2>GALERİ</h2>
        </div>

        <div class="row">
          <div class="col-lg-12 d-flex justify-content-center">
            <ul id="gallery-filters">
              <li
                v-for="(value, index) in filterValues"
                :key="index"
                :class="{ 'filter-active': filteredValue == value }"
                @click="filter(value)">
                {{ value }}
              </li>
            </ul>
          </div>
        </div>

        <div class="row grid">
          <div
            v-for="(image, index) in images"
            :key="index"
            :class="'col-lg-4 col-md-6 grid-item ' + `year${image.date.year}`">
            <div class="gallery-wrap" @click="showImages()">
              <img
                :src="image.url"
                class="img-fluid"
                @load="onImageLoad"/>
              <div class="gallery-info">
                <p>{{ image.date.year }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End gallery Section -->
  </div>
</template>

<script>
import axios from 'axios';
import Isotope from 'isotope-layout';

export default {
  data() {
    return {
      images: [],
      index: 0,
      filterValues: ['2021', '2020', '2019', '2018', '2017', '2016', '2015', '2014', 'HEPSİ'],
      filteredValue: '2021',
      isotope: null,
    };
  },
  methods: {
    fetchData() {
      axios
        .get(`${process.env.VUE_APP_ROOT_URL}/gallery_images.json`)
        .then((res) => {
          this.images = res.data;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
    },
    // Images Gallery
    showImages() {

    },
    // Masonry Layout
    onImageLoad() {
      this.isotope = new Isotope('.grid', {
        itemSelector: '.grid-item',
        layoutMode: 'masonry',
        filter: `.year${this.filteredValue}`,
      });
    },
    filter(value) {
      this.filteredValue = value;
      if (value === 'HEPSİ') {
        this.isotope.arrange({
          filter: false,
        });
      } else {
        this.isotope.arrange({
          filter: `.year${value}`,
        });
      }
    },
  },
  created() {
    this.fetchData();
  },
};
</script>

<style>
.blueimp-gallery {
  background: rgba(0, 0, 0, 0.75);
}
/*--------------------------------------------------------------
# Gallery
--------------------------------------------------------------*/
.gallery .grid-item {
  margin-bottom: 30px;
}
.gallery #gallery-filters {
  padding: 0;
  margin: 0 auto 15px auto;
  list-style: none;
  text-align: center;
  border-radius: 50px;
  padding: 2px 15px;
}
.gallery #gallery-filters li {
  cursor: pointer;
  display: inline-block;
  padding: 8px 16px 10px 16px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  color: #fff;
  background: rgba(255, 255, 255, 0.1);
  margin: 0 3px 10px 3px;
  transition: all 0.3s ease-in-out;
  border-radius: 4px;
}
.gallery #gallery-filters li:hover,
.gallery #gallery-filters li.filter-active {
  background: #ffc107;
}
.gallery #gallery-filters li:last-child {
  margin-right: 0;
}
.gallery .gallery-wrap {
  transition: 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  background: rgba(255, 255, 255, 0.1);
  cursor: pointer;
}
.gallery .gallery-wrap img {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.gallery .gallery-wrap:hover img {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}
.gallery .gallery-wrap .gallery-info {
  position: absolute;
  top: 0;
  left: 0;
  right: 5px;
  bottom: 0;
  text-align: center;
  z-index: 3;
  transition: all ease-in-out 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}
.gallery .gallery-wrap .gallery-info p {
  color: #ffc107;
  font-size: 14px;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
}
</style>
