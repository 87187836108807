<template>
  <div class="credits">
    Designed by <a href="http://btenca.com/" target="_blank">Btenca</a>
  </div>
</template>

<style>
/*--------------------------------------------------------------
# Credits
--------------------------------------------------------------*/
.credits {
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 15px;
  text-align: right;
  font-size: 13px;
  color: #fff;
  z-index: 999999;
}
@media (max-width: 992px) {
  .credits {
    text-align: center;
    padding: 10px;
    background: rgba(0, 0, 0, 0.8);
  }
}
.credits a {
  color: #ffc107;
  transition: 0.3s;
}
.credits a:hover {
  color: #fff;
}
</style>
